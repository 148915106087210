import { TestFileMetadata } from '../files/data';

export const TestAccountState = ({
    sansAccountId = '12345',
    sansEmail = 'test@sans.org',
    name = 'Test User',
} = {}) => ({
    sansAccountId,
    sansEmail,
    name,
});

export const TestConfigState = ({
    globalEndpoints = {
        sansUrl: 'test.sans.org',
    },
    integrations = {
        cloudProviders: {
            providers: {
                default: ['Azure'],
            },
        },
    },
    features = {
        filesApiProxy: {
            getStudentFiles: {
                version: 1,
            },
        },
    },
} = {}) => ({
    globalEndpoints,
    integrations,
    features,
});

export const TestFilesStateSingle = (lab, { fileName = undefined } = {}) => ({
    files: {
        [lab.name]: {
            eventProductId: lab.event_product_id,
            labID: lab.id,
            labName: lab.name,
            instance: lab.name,
            metadata: [TestFileMetadata(lab, { fileName })],
        },
    },
});
