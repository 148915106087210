/**
 * @module mockData/labs
 */

/**
 * @summary - Create a test lab for automated testing. The function provides some defaults.
 *
 * Create a test lab. By default, the function produces an OnDemand lab, `CSE100-I01-GEN`, an OnDeman course
 * with an overview
 *
 * __Basic Usage__
 * ```js
 *  const lab = TestLab();
 * ```
 *
 * __Live Labs__: To produce a live lab, set the `modality` option to `LIVE` and make sure to
 * set an `eventProductId`.
 *
 * ```js
 *  const lab = TestLab({ modality: 'LIVE', eventProductId: 1234 });
 * ```
 *
 * __A & B Sections__: To produce a lab with A and B sections, utilize the `course` and
 * `section` option parameters.
 *
 * ```js
 *  const labA = TestLab({course: 'SEC123A', section: '1'});
 *  const labB = TestLab({course: 'SEC123B', section: '6'});
 * ```
 *
 * __Additional Lab Instructions__: To add additional lab instructions, include them via
 * the `additionalInstructions` array. Since instructions should be related to labs,
 * it is also recommended to set the `id` option and ensure it matches all `field_lab_id`s.
 * ```js
 *  TestLab({
 *      id: 10,
 *      additionalInstructions: [
 *          {
 *              field_lab_id: 1,
 *              title: 'Foo',
 *              field_instruction_type: 'Bar',
 *              field_lab_instruction_display_na: 'Baz',
 *              field_lab_instruction: '<p>This is a test lab</p>',
 *          }
 *      ]
 *  })
 * ```
 *
 *
 * @param {{
 *      id?: number,
 *      course?: string,
 *      modality?: string,
 *      version?: string,
 *      productId?: number,
 *      courseName?: string,
 *      section?: '1'|'6',
 *      nameSectionSuffix?: string,
 *      eventProductId?: number|null,
 *      ovpnWindows?: object|null,
 *      ovpnLinux?: object|null,
 *      type?: string,
 *      labSeries?: string|null,
 *      sharedEnvironment?: string|null,
 *      eventId?: number,
 *      labStart?: string,
 *      utcLabStart?: string,
 *      userProductCacheId?: number,
 *      expirationDate?: string,
 *      lab2StartException?: string|null,
 *      eventName?: string,
 *      instructions?: object,
 *      additionalInstructions?: Object
 * }} [options]
 *
 * @returns {import("../../types/types").LabProduct}
 **/
export const TestLab = function TestLab({
    id = 100,
    course = 'CSE100',
    modality = 'GEN',
    version = 'I01',
    productId = 150,
    section = '1',
    courseName = `${course}: Automated Test Lab`,
    eventProductId = null,
    nameSectionSuffix = '',
    instructions = {
        Overview: [
            {
                field_lab_id: id,
                title: `${course}${nameSectionSuffix}-${version}-${modality}`,
                field_instruction_type: 'Overview',
                field_lab_instruction_display_na:
                    'Lab Environment Access Instructions',
                field_lab_instruction: '<p>This is a test lab</p>',
            },
        ],
    },
    additionalInstructions = {},
    ovpnWindows = null,
    ovpnLinux = null,
    type = 'HTTP_AUTH',
    labSeries = null,
    sharedEnvironment = null,
    eventId = 1032,
    labStart = '2023-01-01',
    utcLabStart = '2023-01-01 18:35:25',
    userProductCacheId = 1000000,
    expirationDate = '2023-04-01 18:35:25',
    lab2StartException = null,
    eventName = 'Mocked Live Event 2024',
} = {}) {
    // First, copy our parameter instructions.
    const inst = {
        ...instructions,
    };

    // Next, for each additional instruction key (ie, Overview, Licensing),
    // add the values of that key to the end of relevent section in inst
    Object.keys(additionalInstructions).forEach((instKey) => {
        if (!inst[instKey]) {
            inst[instKey] = [];
        }

        if (additionalInstructions[instKey]) {
            additionalInstructions[instKey].forEach((instruction) => {
                inst[instKey].push({
                    ...instruction,
                    field_lab_id: id,
                    title: `${course}${nameSectionSuffix}-${version}-${modality}`,
                    field_instruction_type: instKey,
                });
            });
        }
    });
    return {
        instructions: inst,
        id,
        name: `${course}${nameSectionSuffix}-${version}-${modality}`,
        product_id: productId,
        section,
        ovpn_windows: ovpnWindows,
        ovpn_linux: ovpnLinux,
        type,
        lab_series: labSeries,
        shared_environment: sharedEnvironment,
        course_name: courseName,
        event_id: eventId,
        event_product_id: eventProductId,
        event_name: eventName,
        lab_start: labStart,
        utc_lab_start: utcLabStart,
        user_product_cache_id: userProductCacheId,
        timezone_abbreviation: null,
        expiration_date: expirationDate,
        lab_2_start_exception: lab2StartException,
        testExtras: {
            courseCatalog: course,
        },
    };
};

export const formatInstructionsAsRaw = (lab) => {
    let reformattedInstructions = [];

    Object.keys(lab.instructions).forEach((instructionType) => {
        reformattedInstructions = reformattedInstructions.concat(lab.instructions[instructionType]);
    });

    return {
        ...lab,
        instructions: reformattedInstructions,
    };
};
