import { setupStore } from '../../../../app/store';
import LabDisplay from './lab-display';
import { Provider } from 'react-redux';
import React from 'react';
import { TestAccountState, TestConfigState } from '../../../../mocks/redux/data';
import { TestLab, formatInstructionsAsRaw } from '../../../../mocks/labs/data';

export const MockedState = {
    accountReducer: TestAccountState(),
    configReducer: {
        config: TestConfigState(),
    },
    labReducer: {
        labResults: [],
    },
};

export const mockedLabs = {
    singleGeneric: [formatInstructionsAsRaw(TestLab({
        id: 1,
        productId: 1,
    }))],
    onDemandGeneric: [
        formatInstructionsAsRaw(TestLab({ course: 'CSE101', id: 2, productId: 2, nameSectionSuffix: 'A' })),
        formatInstructionsAsRaw(TestLab({
            course: 'CSE101',
            id: 3,
            productId: 2,
            nameSectionSuffix: 'B',
            instructions: {},
            additionalInstructions: {
                Overview: [
                    {
                        field_lab_instruction_display_na:
                            'Test Lab B Overview',
                        field_lab_instruction: '<p>This is a test lab</p>',
                    },
                ],
            },
        })),
    ],
    liveGeneric: [
        formatInstructionsAsRaw(TestLab({
            course: 'CSE102',
            id: 4,
            productId: 3,
            eventProductId: 123,
            nameSectionSuffix: 'A',
            eventName: 'Automated Live Test Lab',
            modality: 'LIVE',
        })),
        formatInstructionsAsRaw(TestLab({
            course: 'CSE102',
            id: 5,
            productId: 3,
            eventProductId: 123,
            section: '6',
            nameSectionSuffix: 'B',
            eventName: 'Automated Live Test Lab',
            modality: 'LIVE',
        })),
    ],
};

export default {
    title: 'Layout/Lab Display',
    component: LabDisplay,
    decorators: [(Story) =>
        <Provider store={ setupStore(MockedState) } >
            <Story />
        </Provider>,
    ],
    excludeStories: ['MockedState', 'mockedLabs'],
    parameters: {
        controls: { exclude: ['accountReducer', 'fetchAccountDetails', 'rangeProvisioningReducer'] },
    },
};

export const Default = {
    args: {
        labResults: [],
    },

};

export const SingleGeneric = {
    args: {
        labResults: [mockedLabs.singleGeneric[0]],
    },
    parameters: {
        ...Default.parameters,
    },
};

export const OnDemandGeneric = {
    args: {
        labResults: [
            mockedLabs.onDemandGeneric[0],
            mockedLabs.onDemandGeneric[1],
        ],
    },
    parameters: {
        ...Default.parameters,
    },
};

export const LiveGeneric = {
    args: {
        labResults: [
            mockedLabs.liveGeneric[0],
            mockedLabs.liveGeneric[1],
        ],
    },
    parameters: {
        ...Default.parameters,
    },
};

export const MixedGeneric = {
    args: {
        labResults: [
            mockedLabs.singleGeneric[0],
            mockedLabs.onDemandGeneric[0],
            mockedLabs.onDemandGeneric[1],
            mockedLabs.liveGeneric[0],
            mockedLabs.liveGeneric[1],
        ],
    },
};
