import { createLab } from '../submodules/shared/utils/fakelab';

const arsenalIntegration = {
    displayName: 'Arsenal Tools',
    instructionHtml: '<p>For arsenal tools, click the button</p>',
};

const cellebriteIntegration = {
    displayName: 'Cellebrite Tools',
    instructionHtml: '<p>For Cellebrite tools, click the button</p>',
};

const corelliumIntegration = {
    displayName: 'Corellium Platform Access',
    instructionHtml:
        "<p>If you have completed your registration, you may log in <a href='https://sans.enterprise.corellium.com/login'>here</a>. If you have not completed your registration (after clicking the button above) check your email.</p>",
};

const shodanIntegration = {
    displayName: 'Shodan Tools',
    instructionHtml:
        "<p>Click 'Get Shodan License' to retrieve your Shodan license key. You may select the resulting license key," +
        ' or click the resulting license key to copy it to your clipboard.</p>',
};
const IdaProIntegration = {
    displayName: 'IDA Pro',
    instructionHtml:
        "<p>Click 'Register for IDA Pro' to submit your license request. If you're not opting out of a license, " +
        'Hex Rays will follow up with you directly via email with your license key within 48 hours.</p>',
};

const cloudProviders = {
    displayName: 'Cloud Training Environment',
    instructionHtml:
        '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vitae est non risus ' +
        'porttitor tincidunt.</p>',
};

export const xsoar = {
    displayName: 'XSOAR',
    instructionHtml:
        '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vitae est non risus porttitor ' +
        'tincidunt.</p>',
};

const overview456 = {
    displayName: 'Overview',
    instructionHtml:
        '<div>\n' +
        '<p>Welcome to SANS SEC456!</p>\n' +
        '<p>Your SEC456 Course utilizes a range environment accessible via OpenVPN.  ' +
        'The “A” range environment will be ' +
        'used to complete your days 1-5 labs.  Refer to your Electronic Workbook for the setup process.</p>\n' +
        '<p>After you have completed the setup process, ' +
        'your courseware will identify when and how to start your labs.' +
        '</p>\n' +
        '<p>Provisioning your range environment may take up to 60 minutes. A section labeled  “files” will be ' +
        'displayed below when the range environment is ready for use</p>\n' +
        '<p><strong><em>If you encounter any issue with building the range environment or with setting up the VPN ' +
        'connection, please contact us via email at ' +
        "<a href='mailto:email@example.com'>virtual-labs-support@sans.org</a>.</em></strong></p>" +
        "<button class='sans-btn'>Launch Range</button>\n" +
        '</div>',
};

const overview456OD = {
    displayName: 'Overview',
    instructionHtml:
        '<div>\n' +
        '<p>Welcome to SANS SEC456!</p>\n' +
        '<p>Your SEC456 Course utilizes a range environment accessible via OpenVPN.  ' +
        'The “A” range environment will be used to complete your days 1-5 labs.  ' +
        'Refer to your Electronic Workbook for the setup process.</p>\n' +
        '<p> After you have completed the setup process, your courseware will identify when and how to start ' +
        'your labs.</p>\n' +
        '<p>To build your individual range environment, click the button below. The build may take approximately ' +
        '60 minutes to complete.  When the build is complete, an OpenVPN key file link will be available instead ' +
        'of the range environment creation button.</p>\n' +
        '<p><strong>Please note: This range environment will be automatically destroyed after 90 days.  ' +
        'To create a new range environment, return to this screen.</strong></p>\n' +
        '<p><strong><em>If you encounter any issue with building the range environment or with setting up the V' +
        "PN connection, please contact us via email at <a href='mailto:email@example.com'>" +
        'virtual-labs-support@sans.org</a>.</em></strong></p>' +
        "<button class='sans-btn'>Launch Range</button>\n" +
        '</div>',
};

const mockedLabData = {
    fakeLab: 1,
    labId: '900000000001',
    courseCatalog: 'MAU100-01-GEN',
    courseName: 'Mocking and You',
    customInstructions: {
        Licensing: [
            cloudProviders,
            arsenalIntegration,
            cellebriteIntegration,
            corelliumIntegration,
            shodanIntegration,
            IdaProIntegration,
            xsoar,
        ],
    },
};

export const fake388OD = {
    fakeLab: 1,
    labId: 900000000006,
    courseCatalog: 'SEC388-I01-GEN',
    courseName: 'SEC388: Secure Configuration of Flux Capacitors',
    customInstructions: {},
    eventId: 1032,
};

export const fake456Live = {
    fakeLab: 1,
    labId: 900000000002,
    eventId: 900000000002,
    courseCatalog: 'SEC456A-H01-LIVE',
    courseName: 'SEC456: Secure Configuration of Flux Capacitors',
    customInstructions: {
        Overview: [overview456],
    },
    eventProductId: 'sec456a-live-fake',
    labStart: '2023-01-01',
};
export const fake456BLive = {
    fakeLab: 1,
    labId: 900000000005,
    eventId: 900000000005,
    courseCatalog: 'SEC456B-H01-LIVE',
    courseName: 'SEC456: Secure Configuration of Flux Capacitors',
    customInstructions: {
        Overview: [overview456],
    },
    eventProductId: 'sec456b-live-fake',
    labStart: '2023-01-01',
};
export const fake456OD = {
    fakeLab: 1,
    labId: 900000000003,
    courseCatalog: 'SEC456A-H01-GEN',
    courseName: 'SEC456: Secure Configuration of Flux Capacitors',
    customInstructions: {
        Overview: [overview456OD],
    },
    eventId: 1032,
};

export const fake456BOD = {
    fakeLab: 1,
    labId: 900000000004,
    courseCatalog: 'SEC456B-H01-GEN',
    courseName: 'SEC456: Secure Configuration of Flux Capacitors',
    customInstructions: {
        Overview: [overview456OD],
    },
    eventId: 1032,
};

const drpOverview = {
    Overview: [
        {
            displayName: 'Provision Your Range',
            instructionHtml: `<p>Dynamic range provisioning is supported via the "Launch" button
                below using the given My Labs part number.</p>`,
        },
    ],
};

export const fakeLDR520OD = {
    fakeLab: 1,
    labId: 900000000006,
    courseCatalog: 'LDR520-I01-GEN',
    courseName: 'LDR520: Secure Configuration of Flux Capacitors',
    customInstructions: drpOverview,
    eventId: 1032,
};

export const fake510OD = {
    fakeLab: 1,
    labId: 900000000006,
    courseCatalog: 'SEC510-I01-GEN',
    courseName: 'SEC510: Secure Configuration of Flux Capacitors',
    customInstructions: drpOverview,
    eventId: 1032,
};

export const fake540OD = {
    fakeLab: 1,
    labId: 900000000006,
    courseCatalog: 'SEC540-H01-GEN',
    courseName: 'SEC540: Secure Configuration of Flux Capacitors',
    customInstructions: drpOverview,
    eventId: 1032,
};

export const fake540J01OD = {
    fakeLab: 1,
    labId: 900000000006,
    courseCatalog: 'SEC540-J01-GEN',
    courseName: 'SEC540: Secure Configuration of Flux Capacitors (J01)',
    customInstructions: drpOverview,
    eventId: 1032,
};

export const fake560OD = {
    fakeLab: 1,
    labId: 900000000005,
    courseCatalog: 'SEC560-H01-01-GEN',
    courseName: 'SEC560: Secure Configuration of Flux Capacitors',
    customInstructions: {},
    eventId: 1032,
};

export const fake565OD_A = {
    fakeLab: 1,
    labId: 900000000006,
    courseCatalog: 'SEC565A-I01-GEN',
    courseName: 'SEC565: Secure Configuration of Flux Capacitors',
    customInstructions: drpOverview,
    eventId: 1032,
};

export const fake565OD_CTF = {
    fakeLab: 1,
    labId: 900000000006,
    courseCatalog: 'SEC565B-I01-GEN',
    courseName: 'SEC565: Secure Configuration of Flux Capacitors',
    customInstructions: drpOverview,
    eventId: 1032,
};

export const fake599AOD_CTF = {
    fakeLab: 1,
    labId: 900000000006,
    courseCatalog: 'SEC599A-I01-GEN',
    courseName: 'SEC599A: Secure Configuration of Flux Capacitors',
    customInstructions: drpOverview,
    eventId: 1032,
};

export const fake599BOD_CTF = {
    fakeLab: 1,
    labId: 900000000006,
    courseCatalog: 'SEC599B-I01-GEN',
    courseName: 'SEC599B: Secure Configuration of Flux Capacitors',
    customInstructions: drpOverview,
    eventId: 1032,
};

/**
 * The following configures what should show up under "Mocking and You"
 * when viewed in the browser. Typically, it includes the generic mocked
 * lab data with license integrations, as well as courses that support
 * dynamically-provisioned ranges. This exists to prevent having to
 * always update the React component when new courses are available.
 */

export const mockedCourses = [
    createLab(mockedLabData),
    createLab(fakeLDR520OD),
    createLab(fake510OD),
    createLab(fake540OD),
    createLab(fake540J01OD),
    createLab(fake565OD_A),
    createLab(fake565OD_CTF),
    createLab(fake599AOD_CTF),
    createLab(fake599BOD_CTF),
];

export default mockedLabData;
